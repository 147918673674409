import React from "react"

const Head = () => {
  return (
    <>
      <section className='head'>
        <div className='container flexSB'>
          <div className='logo'>
            <img src={"https://skjha.info/wp-content/uploads/2023/02/Add-a-heading-4-1.png"} style={{width:"200px"}}/>
            {/*<h1>A</h1>*/}
            {/*<span>ONLINE EDUCATION & LEARNING</span>*/}
          </div>

          <div className='social'>
            <a href={"https://www.instagram.com/aash_official/reels/"} className='fab fa-instagram icon'></a>
            <a href={"https://www.facebook.com/aasheducation/"} className='fab fa-facebook icon'></a>
            <i href={"https://www.youtube.com/@SK_Jha_Sir"} className='fab fa-youtube icon'></i>
          </div>
        </div>
      </section>
    </>
  )
}

export default Head
