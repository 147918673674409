
const ContactPage = () => {

	return(
		<>
			<section style={{ maxWidth: '800px', margin: '20px auto', padding: '20px', backgroundColor: '#ffffff', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
				<h2>Contact Us</h2>

				<p>
					Visit us at: 57F+CF3, Near, Lohar Ln, Musallahpur Hat, Chak Musallahpur, Muhammadpur, Patna, Bihar 800006
				</p>

				<p>
					Call us at: <a href="tel:+918271947190">082719 47190</a>
				</p>

				<p>
					Email us at: <a href="mailto:contact@aasheducation.com">contact@aasheducation.com</a>
				</p>

				{/* Add a form for additional contact options if needed */}

			</section>
		</>
	)
}
export default ContactPage;
