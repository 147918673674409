import React from "react"
import Heading from "../../common/heading/Heading"
import "./Hero.css"

const Hero = () => {
  return (
    <>
      <section className='hero'>
        <div className='container'>
          <div className='row'>
            <Heading subtitle='WELCOME TO AASH EDUCATION' title='Best Online Education Expertise' />
            <p>To be the preferred destination for quality educational content, fostering continuous learning and skill development via our digital platform.</p>
            {/*<div className='button'>*/}
            {/*  <button className='primary-btn'>*/}
            {/*    GET STARTED NOW <i className='fa fa-long-arrow-alt-right'></i>*/}
            {/*  </button>*/}
            {/*  <button>*/}
            {/*    VIEW COURSE <i className='fa fa-long-arrow-alt-right'></i>*/}
            {/*  </button>*/}
            {/*</div>*/}
          </div>
        </div>
      </section>
      <div className='margin'></div>
    </>
  )
}

export default Hero
