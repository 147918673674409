import React from "react"
import { blog } from "../../../dummydata"
import "./footer.css"
import {Link} from "react-router-dom";

const Footer = () => {
  return (
    <>
      <section className='newletter'>
        <div className='container flexSB'>
          <div className='left row'>
            <h1>Newsletter - Stay tune and get the latest update</h1>
          </div>
          <div className='right row'>
            <input type='text' placeholder='Enter email address' />
            <i className='fa fa-paper-plane'></i>
          </div>
        </div>
      </section>
      <footer>
        <div className='container padding'>
          <div className='box logo'>
            <h1>AASH EDUCATION</h1>
            <span>ONLINE EDUCATION & LEARNING</span>
            <p>Join our community of learners! Connect with us on social media, and feel free to share your insights and suggestions. Your journey towards knowledge enhancement is our priority. .</p>

            <a href={"https://www.instagram.com/aash_official/reels/"} className='fab fa-instagram icon'></a>
            <a href={"https://www.facebook.com/aasheducation/"} className='fab fa-facebook icon'></a>
            <i href={"https://www.youtube.com/@SK_Jha_Sir"} className='fab fa-youtube icon'></i>
          </div>
          <div className='box link'>
            <h3>Explore</h3>
            <ul>
              <li><Link to={"/about"}>About Us</Link></li>
              <li><a href="https://skjha.info/" >Courses</a></li>
              <li><a href="https://skjha.info/" >Blog</a></li>
              <li><Link to={"/contact"}>Contact Us</Link></li>
            </ul>
          </div>
          <div className='box link'>
            <h3>Quick Links</h3>
            <ul>
              <li><a href={"https://skjha.info/terms-and-conditions/"}>Terms</a> </li>
              <li><a href={"https://skjha.info/privacy-policy/"}>Privacy</a></li>
            </ul>
          </div>

          <div className='box last'>
            <h3>Have a Questions?</h3>
            <ul>
              <li>
                <i className='fa fa-map'></i>
                57F+CF3, Near, Lohar Ln, Musallahpur Hat, Chak Musallahpur, Muhammadpur, Patna, Bihar 800006
              </li>
              <li>
                <i className='fa fa-phone-alt'></i>
                082719 47190
              </li>
              <li>
                <i className='fa fa-paper-plane'></i>
                contact@aasheducation.com
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <div className='legal'>
        <p>
          Copyright ©2024 All rights reserved
        </p>
      </div>
    </>
  )
}

export default Footer
