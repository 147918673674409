

const AboutPage =()=>{

	return(
		<>
			<div>
				<section style={{ maxWidth: '800px', margin: '20px auto', padding: '20px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
					<p>Welcome to Aash Education, where our mission is to deliver a seamless and enriching learning experience for individuals from diverse backgrounds. Aash Education is proud to be a global platform that empowers educators worldwide to provide top-tier education through our digital platform.</p>
					<h2>Vision</h2>
					<p>Aash Education aspires to be the foremost destination for high-quality educational content, fostering continuous learning and skill development among our users globally.</p>

					<h2>What We Offer</h2>
					<p><strong>Professional Curation:</strong> Our team meticulously selects and organizes video playlists to ensure that you have access to pertinent and insightful content, curated with expertise.</p>

					<p><strong>Comprehensive Resources:</strong> Aash Education offers an array of downloadable resources, including free PDFs and exclusive premium ebooks, designed to cater to a variety of learning preferences.</p>

					<h2>Global Reach</h2>
					<p>Through our digital platform, educators from any corner of the world can utilize Aash Education to deliver exceptional education to learners worldwide.</p>

					<h2>Connect with Us</h2>
					<p>Join our community of dedicated learners! Connect with Aash Education on <a href="https://www.youtube.com/@SK_Jha_Sir">social media platforms</a>, and feel free to share your insights and suggestions. Your journey towards knowledge enhancement is our top priority.</p>
					<div style={{ backgroundColor: '#ecf0f1', padding: '10px', margin: '10px 0', borderRadius: '5px' }}>
						<p>At Aash Education, we are committed to providing a global platform where educational excellence meets a professional learning environment. Explore our curated content and resources to elevate your learning experience.</p>
					</div>
				</section>
			</div>
		</>
	)
}
export default AboutPage
